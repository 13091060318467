<template>
  <!-- 首页 -->
  <!-- 顶部栏 -->
  <div class="contatain">
    <div class="tab">
      <!-- tab栏 -->
      <div
        class="tab-item"
        v-for="item in tabMenu"
        :key="item.id"
        :class="curTabIndex === item.id ? 'tab-item-active' : ''"
        @click="changeTab(item)"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="flex-between" style="padding: 8px 0">
      <div class="flex">
        <div
          class="creatNewBtn"
          v-hasAuth="'user-add'"
          v-if="curTabIndex === 1"
          @click="addNew"
        >
          新建账号
        </div>
        <div v-hasAuth="'roleMenu-add'">
          <div class="creatNewBtn" v-if="curTabIndex === 2" @click="addNew">
            新建权限
          </div>
        </div>
        <div style="width: 212px; height: 24px"></div>
      </div>
      <div style="display: flex">
        <el-input
          size="mini"
          v-model="searchVal"
          placeholder="请输入关键字"
        ></el-input>
        <el-button style="margin-left: 24px" size="mini">批量管理</el-button>
      </div>
    </div>
    <div class="content">
      <!-- 全部职员table -->
      <template v-hasAuth="'user-list'" v-if="curTabIndex === 1">
        <div
          class="item"
          v-for="(item, i) in UserList"
          :key="`a${i}`"
          :style="{ background: i % 2 !== 0 ? '#fff' : 'rgba(250,249,255,1)' }"
          @click="modify(item)"
        >
          <div class="flex">
            <el-checkbox></el-checkbox>
            <div class="item-index">{{ i + 1 }}</div>
          </div>
          <!-- 剧本题目 -->
          <div style="width: 20%; margin-left: 15px">{{ item.username }}</div>
          <!-- 剧本标签信息 -->
          <div style="width: 12%">{{ item.realName }}</div>

          <div style="width: 20%">{{ item.roleName }}</div>
          <div class="flex-around">
            <div
              class="resetBtn"
              v-hasAuth="'user-resetPassword'"
              @click.stop="resetPassword(item.id)"
            >
              重置密码
            </div>
            <myswitch
              style="margin-left: 15px"
              v-hasAuth="'user-enabled'"
              :visible="item.enabled"
              leftText="正常"
              rightText="锁定"
              @input="lockUser(item)"
            />
            <div
              class="delBtn"
              style="margin-left: 15px"
              v-hasAuth="'user-remove'"
              @click.stop="delUser(item.id)"
            >
              删除账号
            </div>
          </div>
        </div>
      </template>

      <!-- 权限设置table -->
      <template v-hasAuth="'user-roleList'" v-else>
        <div
          class="item"
          v-for="(item, i) in clientList"
          :key="`b${i}`"
          :style="{ background: i % 2 !== 0 ? '#fff' : 'rgba(250,249,255,1)' }"
          @click="modify(item)"
        >
          <div class="flex">
            <el-checkbox></el-checkbox>
            <div class="item-index">{{ i + 1 }}</div>
          </div>
          <!-- 剧本序号 -->
          <div style="width: 20%">{{ item.roleName }}</div>
          <div style="width: 20%">{{ item.roleCode }}</div>
          <div class="flex-end">
            <div class="resetBtn">权限分配</div>
            <div
              class="delBtn"
              style="margin-left: 36px"
              @click.stop="delUser(item.roleId)"
              v-hasAuth="'role-remove'"
            >
              删除类型
            </div>
          </div>
        </div>
      </template>

      <el-pagination
        v-show="curTabIndex === 1"
        class="pagination"
        :total="userCount"
        :current-page="internalModel.currPage"
        :page-size="internalModel.limit"
        layout="prev, pager, next"
        @current-change="handleCurrPageChange"
      ></el-pagination>
    </div>
    <!-- 新增修改用户弹窗 -->
    <div v-hasAuth="'user-add'">
      <el-dialog :visible.sync="accountDialog" width="30%">
        <div slot="title" style="text-align: center">
          <span style="background: #eceafa; padding: 5px">{{
            add_type === 1 ? "新建账号" : "修改账号"
          }}</span>
        </div>
        <div class="flex">
          <div class="title">
            <span style="color: red; font-size: 16px">*</span>登陆名称
          </div>
          <el-input
            onkeyup="value=value.replace(/[\u4E00-\u9FA5]/g,'')"
            style="margin-left: 10px; width: 212px"
            size="mini"
            placeholder="请输入用户名"
            v-model="userFrom.username"
          ></el-input>
        </div>
        <div class="flex">
          <div class="title">
            <span style="color: red; font-size: 16px">*</span>昵名
          </div>
          <el-input
            style="margin: 8px 0 0 10px; width: 212px"
            size="mini"
            placeholder="请输入昵称"
            v-model="userFrom.nickname"
          ></el-input>
        </div>
        <div class="flex">
          <div class="title">
            <span style="color: red; font-size: 16px">*</span>真实姓名
          </div>
          <el-input
            style="margin: 8px 0 0 10px; width: 212px"
            size="mini"
            placeholder="请输入真实姓名"
            v-model="userFrom.realName"
          ></el-input>
        </div>
        <div class="flex">
          <div class="title">
            <span style="color: red; font-size: 16px">*</span>类型
          </div>
          <el-select
            size="mini"
            style="margin: 8px 0 0 10px; width: 212px"
            v-model="userFrom.roleId"
            placeholder="请选择"
          >
            <el-option
              v-for="item in clientList"
              :key="item.id"
              :label="item.roleName"
              :value="item.roleId"
            ></el-option>
          </el-select>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="saveUserMsg">保存</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 权限弹窗 -->
    <el-dialog :visible.sync="authDialog" width="575px" center top="5vh">
      <div slot="title" style="text-align: center">
        <span style="background: #eceafa; padding: 5px">{{
          add_type === 1 ? "新建类型" : "修改类型"
        }}</span>
      </div>
      <div class="dialog">
        <div class="flex">
          <div class="title">
            <span style="color: red; font-size: 16px">*</span>类型名称
          </div>
          <el-input
            style="margin: 8px 0 0 10px; width: 212px"
            size="mini"
            placeholder="请输入类型名称"
            v-model="authForm.roleName"
          ></el-input>
        </div>
        <div class="flex">
          <div class="title">
            <span style="color: red; font-size: 16px">*</span>类型编码
          </div>
          <el-input
            style="margin: 8px 0 0 10px; width: 212px"
            onkeyup="value=value.replace(/[\u4E00-\u9FA5]/g,'')"
            size="mini"
            placeholder="请输入类型编码"
            v-model="authForm.roleCode"
          ></el-input>
        </div>
        <div class="title" style="margin-top: 30px">
          <span style="color: red; font-size: 16px">*</span>权限分配
        </div>
        <el-tree
          :data="treeData"
          :props="props"
          show-checkbox
          ref="tree"
          @check="checkChange"
          node-key="id"
          :check-strictly="true"
          :default-checked-keys="checkedKeys"
          :default-expanded-keys="checkedKeys"
          default-expand-all
        >
        </el-tree>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveAuth">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import myswitch from "./compts/myswitch";
export default {
  name: "Home",
  components: {
    myswitch,
  },
  data() {
    return {
      tabMenu: [
        { name: "全部职员", id: 1 },
        { name: "权限管理", id: 2 },
      ],
      curTabIndex: 1,
      searchVal: "",
      UserList: [],
      clientList: [],
      userFrom: {
        username: "",
        realName: "",
        nickname: "",
        roleId: "",
      },
      authForm: {
        roleId: "",
        roleName: "",
        roleCode: "",
        menuSet: [],
      },
      checkedKeys: [],
      list: [],
      customerModel: {
        clientId: "customer",
        searchName: "",
        currPage: 1,
        limit: 20,
      },
      clientId: "internal",
      internalModel: {
        clientId: "internal",
        searchName: "",
        currPage: 1,
        limit: 20,
      },
      accountDialog: false,
      authDialog: false,
      add_type: 1,
      // 树
      treeData: [],
      props: {
        label: "name",
        children: "subMenus",
      },
      userCount: 0,
    };
  },
  async mounted() {
    this.getClinetUsersList();
    this.getAllClientMsg();
  },
  methods: {
    // 通过check的回调里面获取节点id,再获取节点的node对象
    checkChange(data) {
      const node = this.$refs.tree.getNode(data.id);
      this.setNode(node);
    },
    //递归设置子节点和父节点
    setNode(node) {
      if (node.checked) {
        //如果当前是选中checkbox,则递归设置父节点和父父节点++选中
        this.setParentNode(node);
      } else {
        this.setChildNode(node);
      }
    },
    //递归设置父节点全部选中
    setParentNode(node) {
      if (node.parent) {
        for (const key in node) {
          if (key === "parent") {
            node[key].checked = true;
            this.setParentNode(node[key]);
          }
        }
      }
    },
    //递归设置子节点全部取消选中
    setChildNode(node) {
      if (node.childNodes && node.childNodes.length) {
        node.childNodes.forEach((item) => {
          item.checked = false;
          this.setChildNode(item);
        });
      }
    },
    _data2Tree(arr, parentId = "pId") {
      // 建立树映射，构建树结构
      const treeMap = arr.reduce((map, cur) => {
        map[cur.id] = cur;
        return map;
      }, {});
      const list = arr.reduce((arrList, cur) => {
        const pId = cur[parentId];
        const parent = treeMap[pId];
        if (parent) {
          parent.subMenus
            ? parent.subMenus.push(cur)
            : (parent.subMenus = [cur]);
        } else if (pId == "-1") {
          arrList.push(cur);
        }
        return arrList;
      }, []);
      return list;
    },
    handleCheckChange() {
      let res = this.$refs.tree.getCheckedNodes();
      let arr = [];
      res.forEach((item) => {
        arr.push(item.id);
      });
      this.authForm.menuSet = arr;
    },
    async getAllClientMsg() {
      const res = await this.getRequest.getAllClientMsg({
        clientId: this.clientId,
      });
      if (res.respCode === 0) {
        this.clientList = res.datas;
      }
    },
    async changeTab(item) {
      this.curTabIndex = item.id;
      if (this.curTabIndex == 1) {
        this.getClinetUsersList();
      } else {
        this.getAllClientMsg();
        const res = await this.getRequest.getAllsAuthList();
        const treeData = this._data2Tree(res.datas, "parentId");
        this.treeData = treeData;
      }
    },
    addNew() {
      const flag = this.curTabIndex === 1;
      const form = flag ? "userFrom" : "authForm";
      this[flag ? "accountDialog" : "authDialog"] = true;
      this[form] = this.$options.data()[form];
      !flag && this.$refs.tree.setCheckedKeys([]);
      this.add_type = 1;
    },
    async getClinetUsersList() {
      const { data, count } = await this.getRequest.getClinetUsersList(
        this.internalModel
      );
      this.UserList = data;
      this.userCount = count;
    },
    async getAlluthList() {
      const res = await this.getRequest.getAlluthList();
      this.clientList = res.datas;
    },
    async lockUser(item) {
      let { enabled, id } = item;
      enabled = enabled == 1 ? 0 : 1;
      //  enabled = 1
      const res = await this.getRequest.modifyUserStatus({
        id,
        enabled,
      });
      if (res.respCode === 0) {
        this.$message.success(res.respMsg);
        item.enabled = item.enabled == 1 ? 0 : 1;
      } else {
        this.$message.error(res.respMsg);
      }
      this.getClinetUsersList();
    },
    async saveUserMsg() {
      //验证用户名是否合法，3位以上数字加3位以上字符串
      // const isLegal =
      //   /\d{3,}/.test(this.userFrom.username) &&
      //   /[a-zA-Z]{3,}/.test(this.userFrom.username);
      // 表单验证
      let flag = false;
      Object.keys(this.userFrom).some((item) => {
        if (!this.userFrom[item]) {
          flag = true;
          this.$message.error("请填写完整内容");
        }
        return !this.userFrom[item];
      });
      if (flag) {
        return;
      }

      await this.getRequest.addUserMsg(this.userFrom);
      this.userFrom = this.$options.data().userFrom;
      this.getClinetUsersList();
      this.accountDialog = false;
    },
    async saveAuth() {
      const { authForm } = this;
      this.handleCheckChange();
      const res = await this.getRequest.distributeRoles(authForm);
      if (res.respCode === 0) {
        this.getAllClientMsg();
        this.authDialog = false;
      }
      this.$message[res.respCode == 0 ? "success" : "error"](res.respMsg);
      // const res = await this.getRequest.distributeRoles(this.userFrom);
    },
    async modify(val) {
      const item = JSON.parse(JSON.stringify(val));
      if (this.curTabIndex === 1) {
        this.accountDialog = true;
        this.userFrom = item;
      } else {
        this.authDialog = true;
        this.authForm = item;
        const res = await this.getRequest.getUserRoleMenus(item.roleId);
        const treeData = this._data2Tree(res.datas);
        const checkList = res.datas
          .filter((v) => v.checked == true)
          .map((v) => v.id);

        this.checkedKeys = checkList;
        this.treeData = treeData;
      }
      this.add_type = 2;
    },
    resetPassword(id) {
      this.$confirm("是否重置该用户密码?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        const res = await this.getRequest.resetPassword(id);
        this.$message({
          type: "success",
          message: res.respMsg,
        });
      });
    },
    // 删除内部用户
    delUser(id) {
      this.$confirm(
        `此操作永久删除该${
          this.curTabIndex === 1 ? "用户" : "角色权限"
        }, 是否继续?`,
        "警告",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "error",
        }
      )
        .then(async () => {
          const name = this.curTabIndex == 1 ? "delUser" : "delRole";
          const res = await this.getRequest[name](id);
          this.curTabIndex == 1
            ? this.getClinetUsersList()
            : this.getAllClientMsg();
          this.$message({
            type: res.respCode === 0 ? "success" : "error",
            message: res.respMsg,
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleCurrPageChange(page) {
      this.internalModel.currPage = page;
      this.getClinetUsersList();
    },
  },
};
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
  align-items: center;
}
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.flex-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.contatain {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  // background: #fff;
}
.content {
  padding: 16px 16px;

  position: relative;
  flex: 1;
  background: #fff;
  box-shadow: 2px 2px 10px 0px rgba(38, 28, 87, 0.11);

  border-radius: 4px;
}
.tab {
  display: flex;
  &-item {
    width: 96px;
    height: 24px;
    border-radius: 4px;
    font-size: 18px;
    text-align: center;
    font-weight: 400;
    line-height: 24px;
    color: #000000;
    cursor: pointer;
    margin-right: 35px;
  }
  &-item-active {
    color: #fff;
    background: #7f76bd;
  }
}
.creatNewBtn {
  background-image: url("../../assets/newscr_btn_n@2x.png");
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  width: 212px;
  height: 24px;
  line-height: 24px;
  font-size: 18px;
  cursor: pointer;
}

.item {
  color: rgba(51, 51, 51, 1);
  height: 24px;
  line-height: 24px;
  font-size: 14px;
  border-radius: 4px;
  margin-top: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  .item-index {
    margin-left: 10px;
    min-width: 18px;
    height: 18px;
    border-radius: 4px;
    border: 2px solid rgba(197, 192, 224, 1);
    color: rgba(197, 192, 224, 1);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  }
  .item-title {
    margin-left: 10px;
    width: 25%;
  }
  .item-message {
    margin-left: 10px;
    width: 30%;
  }
  .item-other {
    width: 45%;
    display: flex;
    justify-content: space-around;
  }
  .item-check-true {
    height: 20px;
    width: 80px;
    line-height: 20px;
    text-align: center;
    border-radius: 4px;
    font-size: 14px;
    background: #ffffff;
    border: 1px solid #c5c0e0;
  }
  .item-check-false {
    width: 64px;
    height: 24px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #ffffff;
    line-height: 24px;
  }
  .item-date {
    color: rgba(153, 153, 153, 1);
    margin-left: 10px;
    // width: 30%;
    text-align: right;
  }
  .item-sale {
    width: 96px;
    height: 24px;
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #7f76bd;
    line-height: 24px;
    text-align: right;
  }
  .item-state {
    width: 54px;
    display: flex;
    justify-content: flex-end;
    .state {
      font-size: 14px;
      width: 54px;
      height: 20px;
      border-radius: 4px;
      color: #fff;
      text-align: center;
      line-height: 20px;
    }
  }
}
.resetBtn {
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #c5c0e0;
  line-height: 20px;
  width: 80px;
  text-align: center;
  height: 20px;
  font-size: 14px;
  color: #333;
}
.delBtn {
  width: 80px;
  height: 20px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #f394ac;
  line-height: 20px;
  color: #f394ac;
  text-align: center;
}
.pagination {
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
}
.title {
  width: 96px;
  height: 24px;
  background: #eceafa;
  border-radius: 4px;
  line-height: 24px;
  text-align: center;
}
</style>
